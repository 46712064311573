import { signal } from '@preact/signals-react';

export const filterKeywordsPreviewObject = signal('');

export const filterKeywordsPreviewVisible = signal(false);

export const filterKeywordsPreview = (rowData) => {
    console.log(rowData);
    filterKeywordsPreviewObject.value = JSON.parse(rowData.non_filtered_keywords);
    filterKeywordsPreviewVisible.value = true;
};
