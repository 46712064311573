import { InputText } from 'primereact/inputtext'
import React from 'react'
import { GetClientsIP, emailAndPasswordSignUp, signUpWithEmailAndPassword } from './SignUpLogic'
import { useToastContext } from '../../../context/toastContext/toastContext'

export default function SignUpComponent() {

    const { showToast } = useToastContext();
    const handleClick = (event) => {
        GetClientsIP()
        signUpWithEmailAndPassword(event, showToast);
    };



    return (
        <div className="form-container sign-up-container">
            <form className="form" action="#">
                <h1 className="h1">Create Account</h1>
                <h6 className="h6 mb-3 mt-2">using your email and <br /> password for registration</h6>
                <InputText className="w-18rem mb-2" type="email" name="email" value={emailAndPasswordSignUp.email} onChange={(e) => emailAndPasswordSignUp.email = e.target.value} placeholder="Email" autoComplete='email' />
                <InputText className="w-18rem mb-2" value={emailAndPasswordSignUp.password} onChange={(e) => emailAndPasswordSignUp.password = e.target.value} type="password" placeholder="Password" autoComplete='new-password' />
                <InputText className="w-18rem mb-2" value={emailAndPasswordSignUp.confirmPassword} onChange={(e) => emailAndPasswordSignUp.confirmPassword = e.target.value} type="password" placeholder=" Confirm Password" />
                <button onClick={handleClick} className="button" autoComplete='new-password'>
                    Sign Up
                </button>
            </form>
        </div>
    )
}
