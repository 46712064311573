import React, { useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { getKeywordsList } from '../../../apiCalls/get/getKeywordsLists';
import { handleCopy, handleDelete, handleEdit, keywordsListDataTable } from '../../KeywordsLists/DataTable/DataTableLogic';
import { filterKeywordsPreview } from '../keywordsPreview/FilterKeywordPreviewLogic';
import { filterKeywordsStart } from '../FilterDataTable/FilterDataTableLogic';

export default function FilterKeywordsDataTable() {
    useEffect(() => {
        getKeywordsList();
    }, []);

    const keywordsPreviewTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button className='text-lg font-semibold' label='Preview' severity='help' text raised onClick={() => filterKeywordsPreview(rowData)} />
            </React.Fragment>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button className='text-lg font-semibold' label='Filter' text raised onClick={() => filterKeywordsStart(rowData)} />
            </React.Fragment>
        );
    };

    return (
        <div className='mt-3 flex .rise-up'>
            <DataTable
                className='text-lg max-w-max'
                size='small'
                scrollable
                scrollHeight='46rem'
                value={keywordsListDataTable.value}
                emptyMessage='No Keywords Lists'
                paginator
                rows={11}
                removableSort
                rowsPerPageOptions={[5, 10, 25, 50]}
                dataKey='k_id'
            >
                <Column field='k_id' sortable hidden header='Client'></Column>
                <Column field='client' sortable header='Client'></Column>
                <Column field='niche' sortable header='Niche'></Column>
                <Column field='language' sortable header='Language'></Column>
                <Column header='Keywords' body={keywordsPreviewTemplate} exportable={false}></Column>
                <Column header='Action' body={actionBodyTemplate} exportable={false}></Column>
            </DataTable>
        </div>
    );
}
