import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { acceptSaveKeywordsFilter, saveKeywordsFilterConfirmDialogVisibility, saveKeywordsFilterFormIsLoading } from './saveFilteredKeywordsDialogLogic';

export default function SaveFilteredKeywordsConfirmDialog() {
    return (
        <>
            <Dialog header='Save Filtered Results?' footer='' visible={saveKeywordsFilterConfirmDialogVisibility.value} style={{ width: '30rem' }} onHide={() => (saveKeywordsFilterConfirmDialogVisibility.value = false)}>
                <div className='flex'>
                    <Button className='text-lg font-semibold' text raised severity='danger' label='Cancel' icon='pi pi-times' onClick={() => (saveKeywordsFilterConfirmDialogVisibility.value = false)} />
                    <Button
                        className='text-lg font-semibold ml-auto'
                        severity='success'
                        text
                        raised
                        label='Save'
                        icon={`${saveKeywordsFilterFormIsLoading.value.check ? '' : 'pi pi-check-square'}`}
                        loading={saveKeywordsFilterFormIsLoading.value.loading}
                        onClick={acceptSaveKeywordsFilter}
                    />
                </div>
            </Dialog>
        </>
    );
}
