import { signal } from '@preact/signals-react';
import { editKeywordsListsFormComponentData, editKeywordsListsFormComponentVisibility } from '../editKeywordsListForm/FormLogic';
import { deleteKeywordsListConfirmDialogData, deleteKeywordsListConfirmDialogVisibility } from '../deleteKeywordsList/deleteKeywordsListDialogLogic';
import { copyKeywordsListsFormComponentData, copyKeywordsListsFormComponentVisibility } from '../copyKeywordsList/CopyKeywordsListLogic';
export const keywordsListDataTable = signal([]);

export const handleEdit = (rowData) => {
    editKeywordsListsFormComponentData.k_id = rowData.k_id;
    editKeywordsListsFormComponentData.user_id = rowData.user_id;
    editKeywordsListsFormComponentData.client = rowData.client;
    editKeywordsListsFormComponentData.niche = rowData.niche;
    editKeywordsListsFormComponentData.language = rowData.language;
    editKeywordsListsFormComponentVisibility.value = true;
};

export const handleCopy = (rowData) => {
    console.log(rowData);
    copyKeywordsListsFormComponentData.k_id = rowData.k_id;
    copyKeywordsListsFormComponentData.client = rowData.client;
    copyKeywordsListsFormComponentData.niche = rowData.niche;
    copyKeywordsListsFormComponentData.language = rowData.language;
    copyKeywordsListsFormComponentData.non_filtered_keywords = rowData.non_filtered_keywords;
    copyKeywordsListsFormComponentData.non_filtered_words = rowData.non_filtered_words;
    copyKeywordsListsFormComponentVisibility.value = true;
};

export const handleDelete = (rowData) => {
    deleteKeywordsListConfirmDialogVisibility.value = true;
    deleteKeywordsListConfirmDialogData.k_id = rowData.k_id;
    deleteKeywordsListConfirmDialogData.user_id = rowData.user_id;
    deleteKeywordsListConfirmDialogData.client = rowData.client;
    deleteKeywordsListConfirmDialogData.niche = rowData.niche;
    deleteKeywordsListConfirmDialogData.language = rowData.language;
};
