import React from 'react';
import DataTableComponent from './DataTable/DataTableComponent';
import FormComponent from './addKeywordsListForm/FormComponent';
import EditFormComponent from './editKeywordsListForm/FormComponent';
import DeleteKeywordsListConfirmDialog from './deleteKeywordsList/deleteKeywordsDialog';
import AddNewKeywordListButtonComponent from './addKeywordsListsButton/ButtonComponent';
import KeywordPreviewComponent from './keywordsPreview/KeywordPreviewComponent';
import CopyKeywordsList from './copyKeywordsList/CopyKeywordsListComponent';

export default function KeywordsLists() {
    return (
        <div className='card max-w-max m-auto'>
            <KeywordPreviewComponent />
            <FormComponent />
            <CopyKeywordsList />
            <EditFormComponent />
            <DeleteKeywordsListConfirmDialog />
            <AddNewKeywordListButtonComponent />
            <DataTableComponent />
        </div>
    );
}
