import { Toast } from "primereact/toast";
import { createContext, useContext, useRef } from "react";

// create context
const ToastContext = createContext(undefined);

// wrap context provider to add functionality
export const ToastContextProvider = ({ children }) => {
    const toastRef = useRef(null);

    const showToast = (options) => {
        if (!toastRef.current) return;
        toastRef.current.show(options);
    };

    return (
        <ToastContext.Provider value={{ showToast }}>
            <Toast ref={toastRef} />
            <div>{children}</div>
        </ToastContext.Provider>
    );
};

export const useToastContext = () => {
    const context = useContext(ToastContext);

    if (!context) {
        throw new Error(
            "useToastContext have to be used within ToastContextProvider"
        );
    }

    return context;
};