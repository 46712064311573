import { deepSignal } from 'deepsignal/react';
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { auth } from '../../../config/firebase-config';
import { switchForm } from '../../Login';

export async function GetClientsIP() {
    await fetch('https://api.ipify.org?format=json')
        .then((response) => response.json())
        .then((actualData) => {
            localStorage.setItem('ip', actualData.ip);
        })
        .catch((error) => console.error(error));
}

export const emailAndPasswordSignUp = deepSignal({
    email: '',
    password: '',
    confirmPassword: ''
});

const resetFieldsSignUp = () => {
    emailAndPasswordSignUp.email = '';
    emailAndPasswordSignUp.password = '';
    emailAndPasswordSignUp.confirmPassword = '';
};

// TODO! Make from validation based on the following code
// let validationRegex = [
//     { regex: /.{8,}/ }, // min 8 letters,
//     { regex: /[0-9]/ }, // numbers from 0 - 9
//     { regex: /[a-z]/ }, // letters from a - z (lowercase)
//     { regex: /[A-Z]/ } // letters from A-Z (uppercase),
//     // { regex: /[^A-Za-z0-9]/ } // special characters
// ]

// const validation = () => {
//     validationRegex.forEach((item, i) => {
//         let isValid = item.regex.test(emailAndPasswordSignUp.value);
//         if (isValid) {
//         } else {
//         }

//     })
// }

export const signUpWithEmailAndPassword = async (event, showToast) => {
    event.preventDefault();
    await createUserWithEmailAndPassword(auth, emailAndPasswordSignUp.email, emailAndPasswordSignUp.password)
        .then((userCredential) => {
            // const user = userCredential.user;
            resetFieldsSignUp();
            GetClientsIP();
            console.log('User Credential', userCredential.user);
            localStorage.setItem('token', userCredential.user.accessToken);
            localStorage.setItem('user_Id', userCredential.user.reloadUserInfo.localId);
            localStorage.setItem('display_name', userCredential.user.reloadUserInfo.displayName);
            localStorage.setItem('email', userCredential.user.reloadUserInfo.email);
            localStorage.setItem('profile_picture', userCredential.user.photoURL);
            localStorage.setItem('status', false);
            localStorage.setItem('approved', userCredential.user.emailVerified);
            localStorage.setItem('registration_date', userCredential.user.reloadUserInfo.createdAt);
        })
        .then(() => {
            sendEmailVerification(auth.currentUser).then(() => {
                showToast({
                    severity: 'success',
                    summary: 'You have successfully registered your account. Please check your email and follow email verification process',
                    life: 5000
                });
                switchForm();
            });
        })
        .catch((error) => {
            resetFieldsSignUp();
            console.log(error);

            if (error.code === 'auth/missing-email') {
                showToast({
                    severity: 'error',
                    summary: 'Please enter email',
                    life: 5000
                });
            }

            if (error.code === 'auth/invalid-email') {
                showToast({
                    severity: 'error',
                    summary: 'Please enter valid email/password',
                    life: 5000
                });
            }

            if (error.code === 'auth/missing-password') {
                showToast({
                    severity: 'error',
                    summary: 'Please enter password',
                    life: 5000
                });
            }

            if (error.code === 'auth/email-already-in-use') {
                showToast({
                    severity: 'error',
                    summary: 'This email is already registered. Please choose another one',
                    life: 5000
                });
            }
        });

    console.log(auth.currentUser);
};
