import React from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { closeKeywordsListsForm, inputWarn, keywordsFormIsLoading, newKeywordsListsFormComponentData, newKeywordsListsFormComponentVisibility, submitNewKeywordsListsForm } from './FormLogic';
import { FileUpload } from 'primereact/fileupload';
import { keywordsUpload } from './fileUploadLogic';

export default function FormComponent() {
    return (
        <>
            <Dialog header='Enter Initial Info' footer='' visible={newKeywordsListsFormComponentVisibility.value} style={{ width: '15vw' }} onHide={closeKeywordsListsForm}>
                <InputText className={`text-lg mt-1 mb-2 w-17rem ${inputWarn.input1}`} placeholder='Client' value={newKeywordsListsFormComponentData.client} onChange={(e) => (newKeywordsListsFormComponentData.client = e.target.value)} />
                <InputText className={`text-lg mb-2 w-17rem ${inputWarn.input2}`} placeholder='Niche' value={newKeywordsListsFormComponentData.niche} onChange={(e) => (newKeywordsListsFormComponentData.niche = e.target.value)} />
                <InputText className={`text-lg mb-2 w-17rem ${inputWarn.input3}`} placeholder='Language' value={newKeywordsListsFormComponentData.language} onChange={(e) => (newKeywordsListsFormComponentData.language = e.target.value)} />
                <div className='flex'>
                    <div className='mr-auto'>
                        <FileUpload
                            pt={{
                                basicbutton: { className: 'text-lg font-semibold p-button-text p-button-raised' }
                            }}
                            mode='basic'
                            auto
                            accept={'.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'}
                            customUpload
                            uploadHandler={keywordsUpload}
                        />
                    </div>
                    <div className='ml-auto mr-2 '>
                        <Button text raised className='text-lg font-semibold' label='Save' icon={`${keywordsFormIsLoading.value.check ? 'pi pi-check' : ''}`} loading={keywordsFormIsLoading.value.loading} onClick={submitNewKeywordsListsForm} />
                    </div>
                </div>
                <p>* Enter information to store and manage keywords lists. Choose CSV or XLSX file that contains only one column with unfiltered keywords</p>
            </Dialog>
        </>
    );
}
