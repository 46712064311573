import { showToastHandler } from '../../../config/showToastHandler/showToastHandler';
import { newKeywordsListsFormComponentData } from './FormLogic';

export const keywordsUpload = (e) => {
    let file = e.files[0];
    if (file.name.includes('.csv')) {
        const fileReader = new FileReader();
        fileReader.onload = function (event) {
            const csv = event.target.result; // Here we receive data from CSV
            const data = csv.split('\n').filter(function (el) {
                return el.length !== 0;
            }); //* Here we split string to the Array for KEYWORDS section
            let keywordsInitialData = csv.split('\n').filter(function (el) {
                return el.length !== 0;
            }); //* Here we split string to the Array for WORDS section
            let keywords2Step = []; //Here we create new Array

            keywordsInitialData.forEach((element) => {
                keywords2Step.push(element.replace(/(\r\n|\n|\r)/gm, '').split(' '));
            }); //* Here we go through the Initial array and clean and split every string in to Array od Arrays

            let keywords3Step = [].concat.apply([], keywords2Step); //* Here we create flat Array of Arrays

            let keywords4Step = keywords3Step.filter((c, index) => {
                return keywords3Step.indexOf(c) === index;
            }); //* Here we remove duplicates from flat Array

            let keywords6Step = data.filter((c, index) => {
                return data.indexOf(c) === index;
            });

            keywords6Step = keywords6Step.map((item) => {
                return item.replace(/(\r\n|\n|\r)/gm, '');
            });

            let keywords7Step = keywords6Step.sort();

            let keywords5Step = keywords4Step.sort();

            const columnForKeywords = ['Keyword'];
            const columnForWords = ['Words'];
            // Prepare DataTable

            let _importedDataForKeywords = keywords7Step.map((d) => {
                d = d.split(',');
                return columnForKeywords.reduce((obj, c, i) => {
                    obj[c] = d[i].replace(/['"]+/g, '');
                    return obj;
                }, {});
            });

            let _importedDataForWords = keywords5Step.map((d) => {
                d = d.split(',');
                return columnForWords.reduce((obj, c, i) => {
                    obj[c] = d[i].replace(/['"]+/g, '');
                    return obj;
                }, {});
            });

            _importedDataForKeywords.forEach((item, i) => {
                item.id = i + 1;
            });

            _importedDataForWords.forEach((item, i) => {
                item.id = i + 1;
            });
            newKeywordsListsFormComponentData.non_filtered_keywords = JSON.stringify(_importedDataForKeywords);
            newKeywordsListsFormComponentData.non_filtered_words = JSON.stringify(_importedDataForWords);
        };

        fileReader.readAsText(file);
        showToastHandler.value = {
            severity: 'info',
            message: 'Data From CSV File Imported.'
        };
    } else if (file.name.includes('.xlsx')) {
        import('xlsx').then((xlsx) => {
            const fileReader = new FileReader();
            fileReader.onload = (e) => {
                const wb = xlsx.read(e.target.result, { type: 'array' });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const keywordsInitialData = xlsx.utils.sheet_to_json(ws, { header: 1 });
                let keywordsInitialDataFlat = [].concat.apply([], keywordsInitialData);

                keywordsInitialDataFlat.forEach((element, index) => {
                    if (typeof element !== 'string') {
                        keywordsInitialDataFlat[index] = element.toString();
                    }
                });
                let keywords2Step = [];
                keywordsInitialDataFlat.forEach((element) => {
                    keywords2Step.push(element.replace(/(\r\n|\n|\r)/gm, '').split(' '));
                }); //* here we go through the Initial array and clean and split every string in to Array of Arrays

                let keywords3Step = [].concat.apply([], keywords2Step); //Here we create flat Array of Arrays

                let keywords4Step = keywords3Step.filter((c, index) => {
                    return keywords3Step.indexOf(c) === index;
                }); // Here we remove duplicates from flat Array

                let keywords7Step = [];

                keywordsInitialDataFlat.forEach((element) => {
                    keywords7Step.push(element.replace(/(\r\n|\n|\r)/gm, ''));
                });

                let keywords6Step = keywords7Step.filter((c, index) => {
                    return keywords7Step.indexOf(c) === index;
                });

                let keywords5Step = keywords4Step.sort();

                let keywords8Step = keywords6Step.sort();

                // Prepare DataTable
                const columnForKeywords = ['Keyword'];
                const columnForWords = ['Words'];

                let _importedDataForKeywords = keywords8Step.map((d) => {
                    d = d.split(',');
                    return columnForKeywords.reduce((obj, c, i) => {
                        obj[c] = d[i].replace(/['"]+/g, '');
                        return obj;
                    }, {});
                });

                let _importedDataForWords = keywords5Step.map((d) => {
                    d = d.split(',');
                    return columnForWords.reduce((obj, c, i) => {
                        obj[c] = d[i].replace(/['"]+/g, '');
                        return obj;
                    }, {});
                });
                _importedDataForKeywords.forEach((item, i) => {
                    item.id = i + 1;
                });

                _importedDataForWords.forEach((item, i) => {
                    item.id = i + 1;
                });
                newKeywordsListsFormComponentData.non_filtered_keywords = JSON.stringify(_importedDataForKeywords);
                newKeywordsListsFormComponentData.non_filtered_words = JSON.stringify(_importedDataForWords);
            };

            fileReader.readAsArrayBuffer(file);
            showToastHandler.value = {
                severity: 'info',
                message: 'Data From XSLS File Imported.'
            };
        });
    } else {
        showToastHandler.value = {
            severity: 'error',
            message: 'Wrong Type of File. Please Choose CSV or XLSX File.'
        };
    }
};
