import React from 'react';
import { acceptDeleteKeywordsList, deleteKeywordsFormIsLoading, deleteKeywordsListConfirmDialogData, deleteKeywordsListConfirmDialogVisibility, rejectDeleteKeywordsList } from './deleteKeywordsListDialogLogic';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

export default function DeleteKeywordsListConfirmDialog() {
    return (
        <>
            <Dialog header='DELETE Keywords List?' footer='' visible={deleteKeywordsListConfirmDialogVisibility.value} style={{ width: '30rem' }} onHide={() => (deleteKeywordsListConfirmDialogVisibility.value = false)}>
                <p className='text-lg font-semibold'>{`Client: ${deleteKeywordsListConfirmDialogData.client} and`}</p>
                <p className='text-lg font-semibold -mt-4'>{`Niche: ${deleteKeywordsListConfirmDialogData.niche}`}</p>
                <div className='flex'>
                    <Button className='text-lg font-semibold' text raised label='Cancel' icon='pi pi-times' onClick={() => (deleteKeywordsListConfirmDialogVisibility.value = false)} />
                    <Button
                        className='text-lg font-semibold ml-auto'
                        severity='danger'
                        text
                        raised
                        label='Delete'
                        icon={`${deleteKeywordsFormIsLoading.value.check ? '' : 'pi pi-trash'}`}
                        loading={deleteKeywordsFormIsLoading.value.loading}
                        onClick={acceptDeleteKeywordsList}
                    />
                </div>
            </Dialog>
        </>
    );
}
