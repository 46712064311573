import React, { useEffect } from 'react';
import { applyActionCode } from 'firebase/auth';
import { auth } from '../config/firebase-config';
import { signal } from '@preact/signals-react';
import SignUpComponent from './Login/SignUp/SignUpComponent';
import EmailSignInComponent from './Login/SignIn/Email/EmailSignInComponent';
import GoogleSignInComponent from './Login/SignIn/Google/GoogleSignInComponent';
import FacebookSignInComponent from './Login/SignIn/Facebook/FacebookSignInComponent';
import MicrosoftSignInComponent from './Login/SignIn/Microsoft/MicrosoftSignInComponent';
import { useToastContext } from '../context/toastContext/toastContext';
import { useNavigate } from 'react-router-dom';
import { logOut } from './Login/Logout/LogOut';

//* This is signals for the work with States
const switchFormSignal = signal(true);

//* This signal switches the form from SignUp to SignIn
export const switchForm = () => {
    switchFormSignal.value = !switchFormSignal.value;
};

export const Login = () => {
    const navigate = useNavigate();
    const { showToast } = useToastContext();

    useEffect(() => {
        if (localStorage.getItem('token') !== null && localStorage.getItem('email') !== null) {
            const email = localStorage.getItem('email');
            const jwt = localStorage.getItem('token');
            const tokens = jwt.split('.');
            const firstPart = JSON.parse(atob(tokens[0]));
            const secondPart = JSON.parse(atob(tokens[1]));
            if (firstPart.alg === 'RS256' && secondPart.email === email) {
                navigate('/app');
            } else {
                console.log('Exiting');
                logOut(navigate);
            }
        }
    }, []);

    useEffect(() => {
        let urlFromTheBar = window.location.search;
        if (urlFromTheBar !== '') {
            const oobCode = urlFromTheBar.substring(26, 80);
            // const apiKey = urlFromTheBar.substring(88, 127)
            const send = async () => {
                applyActionCode(auth, oobCode)
                    .then((response) => {
                        showToast({
                            severity: 'success',
                            summary: 'Your Email was successfully verified. Please log in and start using our services.',
                            life: 5000
                        });
                        console.log(response);
                    })
                    .catch((error) => {
                        if (error.code === 'auth/invalid-action-code')
                            showToast({
                                severity: 'error',
                                summary: `Activation Link is expired please click on SignUp or SignIn problem`,
                                life: 5000
                            });
                        console.log(error.code);
                    });
            };

            send();
        }
    }, []);

    return (
        <div className='body flex flex-column'>
            <div className='headline1'>
                <h1>Register Or Log In To Your</h1>
                <div className='headline2'>
                    <h1>PPC-Toolz Account</h1>
                </div>
            </div>
            <div className={`mx-auto container ${switchFormSignal.value ? '' : 'right-panel-active'}`}>
                <SignUpComponent />
                <div className='form-container sign-in-container'>
                    <form className='form' action='#'>
                        <h1 className='h1'>Sign in with</h1>
                        <div className='social-container'>
                            <GoogleSignInComponent />
                            <MicrosoftSignInComponent />
                            <FacebookSignInComponent />
                        </div>
                        <EmailSignInComponent />
                    </form>
                </div>
                <div className='overlay-container'>
                    <div className='overlay'>
                        <div className='overlay-panel overlay-left'>
                            <h1 className='h1'>Welcome Back!</h1>
                            <p className='text-white'>To keep connected with us please login with your personal info</p>
                            <button onClick={switchForm} className='ghost button' id='signIn'>
                                Sign In
                            </button>
                        </div>
                        <div className='overlay-panel overlay-right'>
                            <h1 className='h1'>Hello, Friend!</h1>
                            <p className='text-white'>Enter your personal details and start using our services for free</p>
                            <button onClick={switchForm} className='ghost button' id='signUp'>
                                Sign Up
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='problem'>
                <p className='p'>
                    A problem with Sign Up or Sign In?
                    <a className='problemLink' href='https://ppctoolz.pro/#contact'>
                        Click here
                    </a>
                    and let us help you.
                </p>
            </div>
            <div className='rights-reserved'>
                <p className='p'>
                    © ppctoolz.pro - 2022 | <a href='terms-and-conditions.html'>Terms & Conditions</a> | <a href='privacy-policy.html'>Privacy Policy</a> | <a href='mailto:support@ppctooz.pro'>support@ppctooz.pro</a>
                </p>
            </div>
        </div>
    );
};
