import React from 'react';
import { useNavigate } from 'react-router-dom';
import GoogleIcon from '../../../../assets/images/google.png';
import { signInWithGoogle } from './GoogleSignInLogic';
import { useToastContext } from '../../../../context/toastContext/toastContext';

export default function GoogleSignInComponent() {
    const { showToast } = useToastContext();
    const navigate = useNavigate();

    const handleClick = () => {
        signInWithGoogle(showToast, navigate);
    };

    return (
        <>
            {/* eslint-disable-next-line */}
            <a href='#'>
                <img onClick={handleClick} className='icon' src={GoogleIcon} alt='' />
            </a>
        </>
    );
}
