import { signal } from '@preact/signals-react';
import { deepSignal } from 'deepsignal/react';
import { postKeywordsLists } from '../../../apiCalls/post/postKeywordsList';
import { getKeywordsList } from '../../../apiCalls/get/getKeywordsLists';

export const newKeywordsListsFormComponentVisibility = signal(false);
export const keywordsFormIsLoading = signal({
    check: false,
    loading: false
});
export const newKeywordsListsFormComponentData = deepSignal({
    client: '',
    niche: '',
    language: '',
    non_filtered_keywords: '',
    non_filtered_words: ''
});

export const inputWarn = deepSignal({
    input1: '',
    input2: '',
    input3: ''
});

export const closeKeywordsListsForm = () => {
    newKeywordsListsFormComponentData.client = '';
    newKeywordsListsFormComponentData.niche = '';
    newKeywordsListsFormComponentData.language = '';
    inputWarn.input1 = '';
    inputWarn.input2 = '';
    inputWarn.input3 = '';
    newKeywordsListsFormComponentVisibility.value = false;
};

export const submitNewKeywordsListsForm = () => {
    if (newKeywordsListsFormComponentData.client === '') {
        inputWarn.input1 = 'p-invalid';
    } else {
        inputWarn.input1 = '';
    }
    if (newKeywordsListsFormComponentData.niche === '') {
        inputWarn.input2 = 'p-invalid';
    } else {
        inputWarn.input2 = '';
    }
    if (newKeywordsListsFormComponentData.language === '') {
        inputWarn.input3 = 'p-invalid';
    } else {
        inputWarn.input3 = '';
    }
    if (inputWarn.input1 === '' && inputWarn.input2 === '' && inputWarn.input3 === '') {
        keywordsFormIsLoading.value = {
            check: false,
            loading: true
        };
        postKeywordsLists();
        setTimeout(() => {
            keywordsFormIsLoading.value = {
                check: true,
                loading: false
            };
        }, 2000);
        setTimeout(() => {
            newKeywordsListsFormComponentData.client = '';
            newKeywordsListsFormComponentData.niche = '';
            newKeywordsListsFormComponentData.language = '';
            newKeywordsListsFormComponentVisibility.value = false;
            keywordsFormIsLoading.value = {
                check: false,
                loading: false
            };
            getKeywordsList();
        }, 2500);
    }
};
