import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import App from './App';
import { Login } from './pages/Login';
import { Error } from './pages/Error';
import { NotFound } from './pages/NotFound';
import { Access } from './pages/Access';
import PrivateRoutes from './service/utils/PrivateRoutes';

const AppWrapper = () => {
    let location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <Routes>
            <Route element={<PrivateRoutes />}>
                <Route path='/app/*' element={<App />} />
            </Route>
            <Route path='/' element={<Login />} />
            <Route path='/error' element={<Error />} />
            <Route path='*' element={<NotFound />} />
            <Route path='/access' element={<Access />} />
        </Routes>
    );
};

export default AppWrapper;
