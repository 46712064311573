import { getAdditionalUserInfo, signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../../../config/firebase-config';
import { deepSignal } from 'deepsignal/react';
import axios from 'axios';
import { GetClientsIP } from '../../SignUp/SignUpLogic';
import { postNewUserToDataBase } from '../../../../apiCalls/post/postNewUserToDataBase';

export const emailAndPasswordSignIn = deepSignal({
    email: '',
    password: ''
});
const resetFieldsSignIn = () => {
    emailAndPasswordSignIn.email = '';
    emailAndPasswordSignIn.password = '';
};

export const signInUserWithEmailAndPassword = async (event, showToast, navigate) => {
    event.preventDefault();
    await signInWithEmailAndPassword(auth, emailAndPasswordSignIn.email, emailAndPasswordSignIn.password)
        .then((userCredential) => {
            resetFieldsSignIn();
            GetClientsIP();
            console.log(userCredential);
            // Signed in
            if (userCredential.user.emailVerified) {
                localStorage.setItem('token', userCredential.user.accessToken);
                localStorage.setItem('user_Id', userCredential.user.reloadUserInfo.localId);
                localStorage.setItem('display_name', userCredential.user.reloadUserInfo.displayName);
                localStorage.setItem('email', userCredential.user.reloadUserInfo.email);
                localStorage.setItem('profile_picture', userCredential.user.photoURL);
                localStorage.setItem('status', false);
                localStorage.setItem('approved', userCredential.user.emailVerified);
                localStorage.setItem('registration_date', userCredential.user.reloadUserInfo.createdAt);
                const token = localStorage.getItem('token');
                if (token) {
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
                } else {
                    axios.defaults.headers.common['Authorization'] = null;
                    /*if setting null does not remove `Authorization` header then try     
                            delete axios.defaults.headers.common['Authorization'];
                        */
                }

                window.history.replaceState({}, document.title, '/');
                navigate('/app');
                const additional = getAdditionalUserInfo(userCredential);
                console.log(additional);

                if (additional.isNewUser) {
                    setTimeout(() => {
                        postNewUserToDataBase();
                    }, 1000);
                }
            } else {
                showToast({
                    severity: 'error',
                    summary: 'Please check your email box and follow email verification process',
                    life: 5000
                });
            }
        })
        .catch((error) => {
            if (error.code === 'auth/invalid-login-credentials') {
                showToast({
                    severity: 'error',
                    summary: 'Please enter valid email or password.',
                    life: 5000
                });
            }

            if (error.code === 'auth/invalid-email') {
                showToast({
                    severity: 'error',
                    summary: 'Please enter email.',
                    life: 5000
                });
            }
            if (error.code === 'auth/missing-password') {
                showToast({
                    severity: 'error',
                    summary: 'Please enter password.',
                    life: 5000
                });
            }
        });
};
