import { Button } from 'primereact/button';

import React from 'react';
import { newKeywordsListsFormComponentVisibility } from '../addKeywordsListForm/FormLogic';

export default function AddNewKeywordListButtonComponent() {
    return (
        <div className='flex'>
            <Button
                className='ml-auto text-lg font-semibold'
                label='Add New Keywords List'
                text
                raised
                onClick={() => {
                    newKeywordsListsFormComponentVisibility.value = true;
                }}
            />
        </div>
    );
}
