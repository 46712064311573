import { signal } from '@preact/signals-react';
import { deepSignal } from 'deepsignal/react';
import { putKeywordsList } from '../../../apiCalls/put/putKeywordsList';
import { getKeywordsList } from '../../../apiCalls/get/getKeywordsLists';

export const editKeywordsListsFormComponentVisibility = signal(false);
export const editKeywordsFormIsLoading = signal({
    check: false,
    loading: false
});
export const editKeywordsListsFormComponentData = deepSignal({
    k_id: '',
    user_id: '',
    client: '',
    niche: '',
    language: ''
});

export const editInputWarn = deepSignal({
    input1: '',
    input2: '',
    input3: ''
});

export const closeEditKeywordsListsForm = () => {
    editInputWarn.input1 = '';
    editInputWarn.input2 = '';
    editInputWarn.input3 = '';
    editKeywordsListsFormComponentVisibility.value = false;
};

export const submitEditKeywordsListsForm = () => {
    if (editKeywordsListsFormComponentData.client === '') {
        editInputWarn.input1 = 'p-invalid';
    } else {
        editInputWarn.input1 = '';
    }
    if (editKeywordsListsFormComponentData.niche === '') {
        editInputWarn.input2 = 'p-invalid';
    } else {
        editInputWarn.input2 = '';
    }
    if (editKeywordsListsFormComponentData.language === '') {
        editInputWarn.input3 = 'p-invalid';
    } else {
        editInputWarn.input3 = '';
    }
    if (editInputWarn.input1 === '' && editInputWarn.input2 === '' && editInputWarn.input3 === '') {
        editKeywordsFormIsLoading.value = {
            check: false,
            loading: true
        };
        putKeywordsList();
        setTimeout(() => {
            editKeywordsFormIsLoading.value = {
                check: true,
                loading: false
            };
        }, 2000);
        setTimeout(() => {
            editKeywordsListsFormComponentVisibility.value = false;
            editKeywordsFormIsLoading.value = {
                check: false,
                loading: false
            };
            getKeywordsList();
        }, 2500);
    }
};
