import { copyKeywordsListsFormComponentData } from '../../service/KeywordsLists/copyKeywordsList/CopyKeywordsListLogic';
import { axiosBoilerPlate } from '../axiosBoilerPlate';

export const postCopyKeywordsLists = async () => {
    const data = {
        method: 'POST',
        url: `${process.env.REACT_APP_AXIOS_BASEURL}keywordslists`,
        data: {
            user_id: localStorage.getItem('user_Id'),
            client: copyKeywordsListsFormComponentData.client,
            niche: copyKeywordsListsFormComponentData.niche,
            language: copyKeywordsListsFormComponentData.language,
            non_filtered_keywords: copyKeywordsListsFormComponentData.non_filtered_keywords,
            non_filtered_words: copyKeywordsListsFormComponentData.non_filtered_words
        }
    };

    await axiosBoilerPlate(data);
};
