import { deleteKeywordsListConfirmDialogData } from '../../service/KeywordsLists/deleteKeywordsList/deleteKeywordsListDialogLogic';
import { axiosBoilerPlate } from '../axiosBoilerPlate';

export const deleteKeywordsList = async () => {
    const data = {
        method: 'DELETE',
        url: `${process.env.REACT_APP_AXIOS_BASEURL}keywordslists`,
        data: {
            k_id: deleteKeywordsListConfirmDialogData.k_id,
            user_id: localStorage.getItem('user_Id')
        }
    };
    console.log(data);
    await axiosBoilerPlate(data);
};
