import { showToastHandler } from '../../config/showToastHandler/showToastHandler';
import { keywordsListDataTable } from '../../service/KeywordsLists/DataTable/DataTableLogic';
import { axiosBoilerPlate } from '../axiosBoilerPlate';

export const getKeywordsList = async () => {
    const data = {
        method: 'GET',
        url: `${process.env.REACT_APP_AXIOS_BASE_URL}keywordslists`,
        params: {
            user_id: localStorage.getItem('user_Id')
        },
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
    };

    // const config = {
    //     ...data,
    //     headers: {
    //         Authorization: `Bearer ${localStorage.getItem('access_token')}`
    //     }
    // };

    console.log(data);

    try {
        const answer = await axiosBoilerPlate(data);
        if (answer !== undefined) {
            if (answer && answer.request && answer.request.response) {
                const value = JSON.parse(answer.request.response);
                keywordsListDataTable.value = value.response;
            } else {
                showToastHandler.value = {
                    severity: 'error',
                    message: 'Invalid response from server'
                };
                // Handle the case where the response is not in the expected format
            }
            // Process the result
        } else {
            showToastHandler.value = {
                severity: 'error',
                message: 'No keywords data available'
            };
            // Handle the undefined case
        }
    } catch (error) {
        showToastHandler.value = {
            severity: 'error',
            message: `Error fetching keywords list`
        };
    }

    // const answer = await axiosBoilerPlate(data);
    // const value = JSON.parse(answer.request.response);
    // keywordsListDataTable.value = value.response;
};
