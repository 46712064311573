import axios from 'axios';
import { showToastHandler } from '../../config/showToastHandler/showToastHandler';

export const postNewUserToDataBase = async () => {
    const data = {
        user_id: localStorage.getItem('user_Id'),
        ...(localStorage.getItem('display_name') ? { display_name: localStorage.getItem('display_name') } : { display_name: 'Agent Smith' }),
        email: localStorage.getItem('email'),
        mobile_number: 'No Phone Number',
        ip: localStorage.getItem('ip'),
        status: false,
        approved: localStorage.getItem('approved'),
        registration_date: localStorage.getItem('registration_date'),
        team: false
    };

    await axios({
        method: 'POST',
        url: 'http://localhost:5000/member',
        data
    })
        .then((response) => {
            showToastHandler.value = {
                severity: 'success',
                message: response.data.success
            };
            console.log(response);
        })
        .catch((error) => {
            showToastHandler.value = {
                severity: 'error',
                message: error.response.data.error
            };
        })
        .finally(() => {
            // always executed
        });
};
