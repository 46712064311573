import React from 'react'
import { useToastContext } from '../../../../context/toastContext/toastContext';
import { useNavigate } from "react-router-dom";
import { signInWithFacebook } from './FacebookSignInLogic'
import FacebookIcon from '../../../../assets/images/facebook.png';


export default function FacebookSignInComponent() {
    const { showToast } = useToastContext();
    const navigate = useNavigate()

    const handleClick = (event) => {
        signInWithFacebook(event, showToast, navigate);
    };



    return (
        <>
            {/* eslint-disable-next-line */}
            <a href="#" >
                <img onClick={handleClick} className="icon" src={FacebookIcon} alt="" />
            </a>
        </>
    )
}

