import { Outlet, Navigate } from 'react-router-dom';

const PrivateRoutes = () => {
    if (localStorage.getItem('token') !== null && localStorage.getItem('email') !== null && localStorage.getItem('approved') !== false) {
        const email = localStorage.getItem('email');
        const jwt = localStorage.getItem('token');
        const tokens = jwt.split('.');
        let firstPart;
        let secondPart;
        const checkToken = () => {
            firstPart = JSON.parse(atob(tokens[0]));
            secondPart = JSON.parse(atob(tokens[1]));
        };
        try {
            checkToken();
        } catch (error) {
            localStorage.clear();
            return <Navigate to='/' />;
        }

        if (firstPart.alg === 'RS256' && secondPart.email === email) {
            return <Outlet />;
        } else {
            localStorage.clear();
            return <Navigate to='/' />;
        }
    } else {
        return <Navigate to='/' />;
    }
    // return localStorage.getItem('token') ? <Outlet /> : <Navigate to='/' />;
};

export default PrivateRoutes;
