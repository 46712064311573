import { postNewUserToDataBase } from '../../../../apiCalls/post/postNewUserToDataBase';
import { auth } from '../../../../config/firebase-config';
import { signInWithPopup, GoogleAuthProvider, getAdditionalUserInfo } from 'firebase/auth';
import { GetClientsIP } from '../../SignUp/SignUpLogic';
import axios from 'axios';

export const signInWithGoogle = async (showToast, navigate) => {
    await GetClientsIP();
    const provider = new GoogleAuthProvider();
    await signInWithPopup(auth, provider)
        .then((userCredential) => {
            localStorage.setItem('usercredentials', JSON.stringify(userCredential));
            if (userCredential.user.emailVerified) {
                //* Here we check the IP address of the user

                //* Here we add the user info to the local storage
                localStorage.setItem('token', userCredential.user.accessToken);
                localStorage.setItem('user_Id', userCredential.user.reloadUserInfo.localId);
                localStorage.setItem('display_name', userCredential.user.reloadUserInfo.displayName);
                localStorage.setItem('email', userCredential.user.reloadUserInfo.email);
                localStorage.setItem('profile_picture', userCredential.user.photoURL);
                localStorage.setItem('status', false);
                localStorage.setItem('approved', userCredential.user.emailVerified);
                localStorage.setItem('registration_date', userCredential.user.reloadUserInfo.createdAt);
                // TODO: check if there are need o do this every time int 3 places - here - email and wrapper
                // FIXME: Add js-cookie library to handle JWT token securely
                // TODO: Add a check of the received JWT Token
                const token = localStorage.getItem('token');
                if (token) {
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
                } else {
                    axios.defaults.headers.common['Authorization'] = null;
                }

                //* Here we redirect user to the main page if everything is ok
                window.history.replaceState({}, document.title, '/');
                navigate('/app');

                //* Here we check if the user is new and add it to the database if it is
                const additional = getAdditionalUserInfo(userCredential);
                if (additional.isNewUser) {
                    setTimeout(() => {
                        postNewUserToDataBase();
                    }, 1000);
                }
            } else {
                showToast({
                    severity: 'error',
                    summary: 'Something went wrong, please try again later.',
                    life: 5000
                });
            }
        })
        .catch((error) => {
            console.log(error);
        });
};
