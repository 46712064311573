import React from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { closeCopyKeywordsListsForm, copyInputWarn, copyKeywordsFormIsLoading, copyKeywordsListsFormComponentData, copyKeywordsListsFormComponentVisibility, submitCopyKeywordsListsForm } from './CopyKeywordsListLogic';

export default function CopyKeywordsList() {
    return (
        <>
            <Dialog header='Copy Keywords List' footer='' visible={copyKeywordsListsFormComponentVisibility.value} style={{ width: '15vw' }} onHide={closeCopyKeywordsListsForm}>
                <InputText className={`mt-1 mb-2 w-17rem ${copyInputWarn.input1}`} placeholder='Client' value={copyKeywordsListsFormComponentData.client} onChange={(e) => (copyKeywordsListsFormComponentData.client = e.target.value)} />
                <InputText className={`mb-2 w-17rem ${copyInputWarn.input2}`} placeholder='Niche' value={copyKeywordsListsFormComponentData.niche} onChange={(e) => (copyKeywordsListsFormComponentData.niche = e.target.value)} />
                <InputText className={`mb-2 w-17rem ${copyInputWarn.input3}`} placeholder='Language' value={copyKeywordsListsFormComponentData.language} onChange={(e) => (copyKeywordsListsFormComponentData.language = e.target.value)} />
                <div className='flex justify-content-end mr-2'>
                    <Button className='text-lg font-semibold' text raised label='Save' icon={`${copyKeywordsFormIsLoading.value.check ? 'pi pi-check' : ''}`} loading={copyKeywordsFormIsLoading.value.loading} onClick={submitCopyKeywordsListsForm} />
                </div>
            </Dialog>
        </>
    );
}
