import { signal } from '@preact/signals-react';
import { deepSignal } from 'deepsignal/react';
import { getKeywordsList } from '../../../apiCalls/get/getKeywordsLists';
import { postCopyKeywordsLists } from '../../../apiCalls/post/postCopyKeywordsList';

export const copyKeywordsListsFormComponentVisibility = signal(false);
export const copyKeywordsFormIsLoading = signal({
    check: false,
    loading: false
});
export const copyKeywordsListsFormComponentData = deepSignal({
    k_id: '',
    user_id: '',
    client: '',
    niche: '',
    language: '',
    non_filtered_keywords: '',
    non_filtered_words: ''
});

export const copyInputWarn = deepSignal({
    input1: '',
    input2: '',
    input3: ''
});

export const closeCopyKeywordsListsForm = () => {
    copyInputWarn.input1 = '';
    copyInputWarn.input2 = '';
    copyInputWarn.input3 = '';
    copyKeywordsListsFormComponentVisibility.value = false;
};

export const submitCopyKeywordsListsForm = () => {
    if (copyKeywordsListsFormComponentData.client === '') {
        copyInputWarn.input1 = 'p-invalid';
    } else {
        copyInputWarn.input1 = '';
    }
    if (copyKeywordsListsFormComponentData.niche === '') {
        copyInputWarn.input2 = 'p-invalid';
    } else {
        copyInputWarn.input2 = '';
    }
    if (copyKeywordsListsFormComponentData.language === '') {
        copyInputWarn.input3 = 'p-invalid';
    } else {
        copyInputWarn.input3 = '';
    }
    if (copyInputWarn.input1 === '' && copyInputWarn.input2 === '' && copyInputWarn.input3 === '') {
        copyKeywordsFormIsLoading.value = {
            check: false,
            loading: true
        };
        postCopyKeywordsLists();
        setTimeout(() => {
            copyKeywordsFormIsLoading.value = {
                check: true,
                loading: false
            };
        }, 2000);
        setTimeout(() => {
            copyKeywordsListsFormComponentVisibility.value = false;
            copyKeywordsFormIsLoading.value = {
                check: false,
                loading: false
            };
            getKeywordsList();
        }, 2500);
    }
};
