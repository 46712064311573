import React, { useState, useRef, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { SelectButton } from 'primereact/selectbutton';
import './../css/active.css';
import { getNewToken } from './Login/SignIn/User/User';

const GenerateADS = () => {
    const [defaultHeadline1, setDefaultHeadline1] = useState({
        headline: '',
        position: ''
    });
    const [headline1, setHeadline1] = useState({
        headline: '',
        position: ''
    });
    const [headline2, setHeadline2] = useState({
        headline: '',
        position: ''
    });
    const [headline3, setHeadline3] = useState({
        headline: '',
        position: ''
    });
    const [headline4, setHeadline4] = useState({
        headline: '',
        position: ''
    });
    const [headline5, setHeadline5] = useState({
        headline: '',
        position: ''
    });
    const [headline6, setHeadline6] = useState({
        headline: '',
        position: ''
    });
    const [headline7, setHeadline7] = useState({
        headline: '',
        position: ''
    });
    const [headline8, setHeadline8] = useState({
        headline: '',
        position: ''
    });
    const [headline9, setHeadline9] = useState({
        headline: '',
        position: ''
    });
    const [headline10, setHeadline10] = useState({
        headline: '',
        position: ''
    });
    const [headline11, setHeadline11] = useState({
        headline: '',
        position: ''
    });
    const [headline12, setHeadline12] = useState({
        headline: '',
        position: ''
    });
    const [headline13, setHeadline13] = useState({
        headline: '',
        position: ''
    });
    const [headline14, setHeadline14] = useState({
        headline: '',
        position: ''
    });
    const [headline15, setHeadline15] = useState({
        headline: '',
        position: ''
    });

    const [headline1Severity, setHeadline1Severity] = useState(true);
    const [headline2Severity, setHeadline2Severity] = useState(true);
    const [headline3Severity, setHeadline3Severity] = useState(true);
    const [headline4Severity, setHeadline4Severity] = useState(true);
    const [headline5Severity, setHeadline5Severity] = useState(true);
    const [headline6Severity, setHeadline6Severity] = useState(true);
    const [headline7Severity, setHeadline7Severity] = useState(true);
    const [headline8Severity, setHeadline8Severity] = useState(true);
    const [headline9Severity, setHeadline9Severity] = useState(true);
    const [headline10Severity, setHeadline10Severity] = useState(true);
    const [headline11Severity, setHeadline11Severity] = useState(true);
    const [headline12Severity, setHeadline12Severity] = useState(true);
    const [headline13Severity, setHeadline13Severity] = useState(true);
    const [headline14Severity, setHeadline14Severity] = useState(true);
    const [headline15Severity, setHeadline15Severity] = useState(true);

    const [description1, setDescription1] = useState({
        description: '',
        position: ''
    });
    const [description2, setDescription2] = useState({
        description: '',
        position: ''
    });
    const [description3, setDescription3] = useState({
        description: '',
        position: ''
    });
    const [description4, setDescription4] = useState({
        description: '',
        position: ''
    });

    const [description1Severity, setDescription1Severity] = useState(true);
    const [description2Severity, setDescription2Severity] = useState(true);
    const [description3Severity, setDescription3Severity] = useState(true);
    const [description4Severity, setDescription4Severity] = useState(true);

    const [finalPath1, setFinalPath1] = useState({
        path: '',
        position: ''
    });
    const [finalPath2, setFinalPath2] = useState({
        path: '',
        position: ''
    });

    const [path1Severity, setPath1Severity] = useState(true);
    const [path2Severity, setPath2Severity] = useState(true);

    const [finalURL, setFinalURL] = useState({
        URL: '',
        position: ''
    });
    const [finalMobileURL, setFinalMobileURL] = useState({
        URL: '',
        position: ''
    });

    const [finalURLSeverity, setFinalURLSeverity] = useState(true);
    const [finalMobileURLSeverity, setFinalMobileURLSeverity] = useState(true);

    const toast = useRef(null);

    const headlinePosition = [
        { name: 'P1', value: 1 },
        { name: 'P2', value: 2 },
        { name: 'P3', value: 3 }
    ];

    const descriptionPosition = [
        { name: 'P1', value: 1 },
        { name: 'P2', value: 2 }
    ];

    //* Start of Headline Section
    useEffect(() => {
        if (headline1.headline.length <= 30) {
            setHeadline1Severity(true);
        }
        if (headline1.headline.length > 30) {
            setHeadline1Severity(false);
        }
    }, [headline1]);

    useEffect(() => {
        if (headline2.headline.length <= 30) {
            setHeadline2Severity(true);
        }
        if (headline2.headline.length > 30) {
            setHeadline2Severity(false);
        }
    }, [headline2]);

    useEffect(() => {
        if (headline3.headline.length <= 30) {
            setHeadline3Severity(true);
        }
        if (headline3.headline.length > 30) {
            setHeadline3Severity(false);
        }
    }, [headline3]);

    useEffect(() => {
        if (headline4.headline.length <= 30) {
            setHeadline4Severity(true);
        }
        if (headline4.headline.length > 30) {
            setHeadline4Severity(false);
        }
    }, [headline4]);

    useEffect(() => {
        if (headline5.headline.length <= 30) {
            setHeadline5Severity(true);
        }
        if (headline5.headline.length > 30) {
            setHeadline5Severity(false);
        }
    }, [headline5]);

    useEffect(() => {
        if (headline6.headline.length <= 30) {
            setHeadline6Severity(true);
        }
        if (headline6.headline.length > 30) {
            setHeadline6Severity(false);
        }
    }, [headline6]);

    useEffect(() => {
        if (headline7.headline.length <= 30) {
            setHeadline7Severity(true);
        }
        if (headline7.headline.length > 30) {
            setHeadline7Severity(false);
        }
    }, [headline7]);

    useEffect(() => {
        if (headline8.headline.length <= 30) {
            setHeadline8Severity(true);
        }
        if (headline8.headline.length > 30) {
            setHeadline8Severity(false);
        }
    }, [headline8]);

    useEffect(() => {
        if (headline9.headline.length <= 30) {
            setHeadline9Severity(true);
        }
        if (headline9.headline.length > 30) {
            setHeadline9Severity(false);
        }
    }, [headline9]);

    useEffect(() => {
        if (headline10.headline.length <= 30) {
            setHeadline10Severity(true);
        }
        if (headline10.headline.length > 30) {
            setHeadline10Severity(false);
        }
    }, [headline10]);

    useEffect(() => {
        if (headline11.headline.length <= 30) {
            setHeadline11Severity(true);
        }
        if (headline11.headline.length > 30) {
            setHeadline11Severity(false);
        }
    }, [headline11]);

    useEffect(() => {
        if (headline12.headline.length <= 30) {
            setHeadline12Severity(true);
        }
        if (headline12.headline.length > 30) {
            setHeadline12Severity(false);
        }
    }, [headline12]);

    useEffect(() => {
        if (headline13.headline.length <= 30) {
            setHeadline13Severity(true);
        }
        if (headline13.headline.length > 30) {
            setHeadline13Severity(false);
        }
    }, [headline13]);

    useEffect(() => {
        if (headline14.headline.length <= 30) {
            setHeadline14Severity(true);
        }
        if (headline14.headline.length > 30) {
            setHeadline14Severity(false);
        }
    }, [headline14]);

    useEffect(() => {
        if (headline15.headline.length <= 30) {
            setHeadline15Severity(true);
        }
        if (headline15.headline.length > 30) {
            setHeadline15Severity(false);
        }
    }, [headline15]);

    // //* Start of Description Section

    useEffect(() => {
        if (description1.description.length <= 90) {
            setDescription1Severity(true);
        }
        if (description1.description.length > 90) {
            setDescription1Severity(false);
        }
    }, [description1]);

    useEffect(() => {
        if (description2.description.length <= 90) {
            setDescription2Severity(true);
        }
        if (description2.description.length > 90) {
            setDescription2Severity(false);
        }
    }, [description2]);

    useEffect(() => {
        if (description3.description.length <= 90) {
            setDescription3Severity(true);
        }
        if (description3.description.length > 90) {
            setDescription3Severity(false);
        }
    }, [description3]);

    useEffect(() => {
        if (description4.description.length <= 90) {
            setDescription4Severity(true);
        }
        if (description4.description.length > 90) {
            setDescription4Severity(false);
        }
    }, [description4]);

    // //* Start Of Path Section

    useEffect(() => {
        if (finalPath1.path.length <= 15) {
            setPath1Severity(true);
        }
        if (finalPath1.path.length > 15) {
            setPath1Severity(false);
        }
    }, [finalPath1]);

    useEffect(() => {
        if (finalPath2.path.length <= 15) {
            setPath2Severity(true);
        }
        if (finalPath2.path.length > 15) {
            setPath2Severity(false);
        }
    }, [finalPath2]);

    // //* Start Of URL Section

    useEffect(() => {
        if (finalURL.URL.length <= 90) {
            setFinalURLSeverity(true);
        }
        if (finalURL.URL.length > 90) {
            setFinalURLSeverity(false);
        }
    }, [finalURL]);

    useEffect(() => {
        if (finalMobileURL.URL.length <= 90) {
            setFinalMobileURLSeverity(true);
        }
        if (finalMobileURL.URL.length > 90) {
            setFinalMobileURLSeverity(false);
        }
    }, [finalMobileURL]);

    return (
        <div className='select-none'>
            <Button text raised label='Save' icon='pi pi-check-square' className='ml-auto text-lg font-semibold' onClick={getNewToken} />
            <Toast ref={toast} />
            <div id='box' className='card w-full'>
                <h5>1 - Add Your Ad Copy</h5>
                <div className='text flex '>
                    <div>
                        <div className='flex'>
                            <div className={`flex align-items-center justify-content w-3rem`}>
                                <h5 className='m-auto'>H1</h5>
                            </div>
                            <InputText
                                className='ml-1 w-17rem'
                                placeholder='Headline 1'
                                value={headline1.headline}
                                onChange={(e) => {
                                    setHeadline1({ ...headline1, headline: e.target.value });
                                }}
                            />
                            <div className={`flex ml-1 align-items-center justify-content border-400 border-round-sm border-1 border-solid-center w-3rem ${headline1Severity ? 'bg-green-100' : 'bg-pink-200'}`}>
                                <h5 className='m-auto'>{`${headline1.headline.length}`}</h5>
                            </div>
                            <div className='ml-1'>
                                <SelectButton value={headline1.position} onChange={(e) => setHeadline1({ ...headline1, position: e.value })} optionLabel='name' options={headlinePosition} />
                            </div>
                        </div>
                        <div className='flex mt-1'>
                            <div className={`flex align-items-center justify-content w-3rem`}>
                                <h5 className='m-auto'>H2</h5>
                            </div>
                            <InputText className='ml-1 w-17rem' placeholder='Headline 2' value={headline2.headline} onChange={(e) => setHeadline2({ ...headline2, headline: e.target.value })} />
                            <div className={`flex ml-1 align-items-center justify-content border-400 border-round-sm border-1 border-solid-center w-3rem ${headline2Severity ? 'bg-green-100' : 'bg-pink-200'}`}>
                                <h5 className='m-auto'>{`${headline2.headline.length}`}</h5>
                            </div>
                            <div className='ml-1'>
                                <SelectButton value={headline2.position} onChange={(e) => setHeadline2({ ...headline2, position: e.value })} optionLabel='name' options={headlinePosition} />
                            </div>
                        </div>
                        <div className='flex mt-1'>
                            <div className={`flex align-items-center justify-content w-3rem`}>
                                <h5 className='m-auto'>H3</h5>
                            </div>
                            <InputText className='ml-1 w-17rem' placeholder='Headline 3' value={headline3.headline} onChange={(e) => setHeadline3({ ...headline3, headline: e.target.value })} />
                            <div className={`flex ml-1 align-items-center justify-content border-400 border-round-sm border-1 border-solid-center w-3rem ${headline3Severity ? 'bg-green-100' : 'bg-pink-200'}`}>
                                <h5 className='m-auto'>{`${headline3.headline.length}`}</h5>
                            </div>
                            <div className='ml-1'>
                                <SelectButton value={headline3.position} onChange={(e) => setHeadline3({ ...headline3, position: e.value })} optionLabel='name' options={headlinePosition} />
                            </div>
                        </div>
                        <div className='flex mt-1'>
                            <div className={`flex align-items-center justify-content w-3rem`}>
                                <h5 className='m-auto'>H4</h5>
                            </div>
                            <InputText className='ml-1 w-17rem' placeholder='Headline 4' value={headline4.headline} onChange={(e) => setHeadline4({ ...headline4, headline: e.target.value })} />
                            <div className={`flex ml-1 align-items-center justify-content border-400 border-round-sm border-1 border-solid-center w-3rem ${headline4Severity ? 'bg-green-100' : 'bg-pink-200'}`}>
                                <h5 className='m-auto'>{`${headline4.headline.length}`}</h5>
                            </div>
                            <div className='ml-1'>
                                <SelectButton value={headline4.position} onChange={(e) => setHeadline4({ ...headline4, position: e.value })} optionLabel='name' options={headlinePosition} />
                            </div>
                        </div>
                        <div className='flex mt-1'>
                            <div className={`flex align-items-center justify-content w-3rem`}>
                                <h5 className='m-auto'>H5</h5>
                            </div>
                            <InputText className='ml-1 w-17rem' placeholder='Headline 5' value={headline5.headline} onChange={(e) => setHeadline5({ ...headline5, headline: e.target.value })} />
                            <div className={`flex ml-1 align-items-center justify-content border-400 border-round-sm border-1 border-solid-center w-3rem ${headline5Severity ? 'bg-green-100' : 'bg-pink-200'}`}>
                                <h5 className='m-auto'>{`${headline5.headline.length}`}</h5>
                            </div>
                            <div className='ml-1'>
                                <SelectButton value={headline5.position} onChange={(e) => setHeadline5({ ...headline5, position: e.value })} optionLabel='name' options={headlinePosition} />
                            </div>
                        </div>
                        <div className='flex mt-1'>
                            <div className={`flex align-items-center justify-content w-3rem`}>
                                <h5 className='m-auto'>H6</h5>
                            </div>
                            <InputText className='ml-1 w-17rem' placeholder='Headline 6' value={headline6.headline} onChange={(e) => setHeadline6({ ...headline6, headline: e.target.value })} />
                            <div className={`flex ml-1 align-items-center justify-content border-400 border-round-sm border-1 border-solid-center w-3rem ${headline6Severity ? 'bg-green-100' : 'bg-pink-200'}`}>
                                <h5 className='m-auto'>{`${headline6.headline.length}`}</h5>
                            </div>
                            <div className='ml-1'>
                                <SelectButton value={headline6.position} onChange={(e) => setHeadline6({ ...headline6, position: e.value })} optionLabel='name' options={headlinePosition} />
                            </div>
                        </div>
                        <div className='flex mt-1'>
                            <div className={`flex align-items-center justify-content w-3rem`}>
                                <h5 className='m-auto'>H7</h5>
                            </div>
                            <InputText className='ml-1 w-17rem' placeholder='Headline 7' value={headline7.headline} onChange={(e) => setHeadline7({ ...headline7, headline: e.target.value })} />
                            <div className={`flex ml-1 align-items-center justify-content border-400 border-round-sm border-1 border-solid-center w-3rem ${headline7Severity ? 'bg-green-100' : 'bg-pink-200'}`}>
                                <h5 className='m-auto'>{`${headline7.headline.length}`}</h5>
                            </div>
                            <div className='ml-1'>
                                <SelectButton value={headline7.position} onChange={(e) => setHeadline7({ ...headline7, position: e.value })} optionLabel='name' options={headlinePosition} />
                            </div>
                        </div>
                        <div className='flex mt-1'>
                            <div className={`flex align-items-center justify-content w-3rem`}>
                                <h5 className='m-auto'>H8</h5>
                            </div>
                            <InputText className='ml-1 w-17rem' placeholder='Headline 8' value={headline8.headline} onChange={(e) => setHeadline8({ ...headline8, headline: e.target.value })} />
                            <div className={`flex ml-1 align-items-center justify-content border-400 border-round-sm border-1 border-solid-center w-3rem ${headline8Severity ? 'bg-green-100' : 'bg-pink-200'}`}>
                                <h5 className='m-auto'>{`${headline8.headline.length}`}</h5>
                            </div>
                            <div className='ml-1'>
                                <SelectButton value={headline8.position} onChange={(e) => setHeadline8({ ...headline8, position: e.value })} optionLabel='name' options={headlinePosition} />
                            </div>
                        </div>
                        <div className='flex mt-1'>
                            <div className={`flex align-items-center justify-content w-3rem`}>
                                <h5 className='m-auto'>H9</h5>
                            </div>
                            <InputText className='ml-1 w-17rem' placeholder='Headline 9' value={headline9.headline} onChange={(e) => setHeadline9({ ...headline9, headline: e.target.value })} />
                            <div className={`flex ml-1 align-items-center justify-content border-400 border-round-sm border-1 border-solid-center w-3rem ${headline9Severity ? 'bg-green-100' : 'bg-pink-200'}`}>
                                <h5 className='m-auto'>{`${headline9.headline.length}`}</h5>
                            </div>
                            <div className='ml-1'>
                                <SelectButton value={headline9.position} onChange={(e) => setHeadline9({ ...headline9, position: e.value })} optionLabel='name' options={headlinePosition} />
                            </div>
                        </div>
                        <div className='flex mt-1'>
                            <div className={`flex align-items-center justify-content w-3rem`}>
                                <h5 className='m-auto'>H10</h5>
                            </div>
                            <InputText className='ml-1 w-17rem' placeholder='Headline 10' value={headline10.headline} onChange={(e) => setHeadline10({ ...headline10, headline: e.target.value })} />
                            <div className={`flex ml-1 align-items-center justify-content border-400 border-round-sm border-1 border-solid-center w-3rem ${headline10Severity ? 'bg-green-100' : 'bg-pink-200'}`}>
                                <h5 className='m-auto'>{`${headline10.headline.length}`}</h5>
                            </div>
                            <div className='ml-1'>
                                <SelectButton value={headline10.position} onChange={(e) => setHeadline10({ ...headline10, position: e.value })} optionLabel='name' options={headlinePosition} />
                            </div>
                        </div>
                        <div className='flex mt-1'>
                            <div className={`flex align-items-center justify-content w-3rem`}>
                                <h5 className='m-auto'>H11</h5>
                            </div>
                            <InputText className='ml-1 w-17rem' placeholder='Headline 12' value={headline11.headline} onChange={(e) => setHeadline11({ ...headline11, headline: e.target.value })} />
                            <div className={`flex ml-1 align-items-center justify-content border-400 border-round-sm border-1 border-solid-center w-3rem ${headline11Severity ? 'bg-green-100' : 'bg-pink-200'}`}>
                                <h5 className='m-auto'>{`${headline11.headline.length}`}</h5>
                            </div>
                            <div className='ml-1'>
                                <SelectButton value={headline11.position} onChange={(e) => setHeadline11({ ...headline11, position: e.value })} optionLabel='name' options={headlinePosition} />
                            </div>
                        </div>
                        <div className='flex mt-1'>
                            <div className={`flex align-items-center justify-content w-3rem`}>
                                <h5 className='m-auto'>H12</h5>
                            </div>
                            <InputText className='ml-1 w-17rem' placeholder='Headline 11' value={headline12.headline} onChange={(e) => setHeadline12({ ...headline12, headline: e.target.value })} />
                            <div className={`flex ml-1 align-items-center justify-content border-400 border-round-sm border-1 border-solid-center w-3rem ${headline12Severity ? 'bg-green-100' : 'bg-pink-200'}`}>
                                <h5 className='m-auto'>{`${headline12.headline.length}`}</h5>
                            </div>
                            <div className='ml-1'>
                                <SelectButton value={headline12.position} onChange={(e) => setHeadline12({ ...headline12, position: e.value })} optionLabel='name' options={headlinePosition} />
                            </div>
                        </div>
                        <div className='flex mt-1'>
                            <div className={`flex align-items-center justify-content w-3rem`}>
                                <h5 className='m-auto'>H13</h5>
                            </div>
                            <InputText className='ml-1 w-17rem' placeholder='Headline 13' value={headline13.headline} onChange={(e) => setHeadline13({ ...headline13, headline: e.target.value })} />
                            <div className={`flex ml-1 align-items-center justify-content border-400 border-round-sm border-1 border-solid-center w-3rem ${headline13Severity ? 'bg-green-100' : 'bg-pink-200'}`}>
                                <h5 className='m-auto'>{`${headline13.headline.length}`}</h5>
                            </div>
                            <div className='ml-1'>
                                <SelectButton value={headline13.position} onChange={(e) => setHeadline13({ ...headline13, position: e.value })} optionLabel='name' options={headlinePosition} />
                            </div>
                        </div>
                        <div className='flex mt-1'>
                            <div className={`flex align-items-center justify-content w-3rem`}>
                                <h5 className='m-auto'>H14</h5>
                            </div>
                            <InputText className='ml-1 w-17rem' placeholder='Headline 14' value={headline14.headline} onChange={(e) => setHeadline14({ ...headline14, headline: e.target.value })} />
                            <div className={`flex ml-1 align-items-center justify-content border-400 border-round-sm border-1 border-solid-center w-3rem ${headline14Severity ? 'bg-green-100' : 'bg-pink-200'}`}>
                                <h5 className='m-auto'>{`${headline14.headline.length}`}</h5>
                            </div>
                            <div className='ml-1'>
                                <SelectButton value={headline14.position} onChange={(e) => setHeadline14({ ...headline14, position: e.value })} optionLabel='name' options={headlinePosition} />
                            </div>
                        </div>
                        <div className='flex mt-1'>
                            <div className={`flex align-items-center justify-content w-3rem`}>
                                <h5 className='m-auto'>H15</h5>
                            </div>
                            <InputText className='ml-1 w-17rem' placeholder='Headline 15' value={headline15.headline} onChange={(e) => setHeadline15({ ...headline1, headline: e.target.value })} />
                            <div className={`flex ml-1 align-items-center justify-content border-400 border-round-sm border-1 border-solid-center w-3rem ${headline15Severity ? 'bg-green-100' : 'bg-pink-200'}`}>
                                <h5 className='m-auto'>{`${headline15.headline.length}`}</h5>
                            </div>
                            <div className='ml-1'>
                                <SelectButton value={headline15.position} onChange={(e) => setHeadline15({ ...headline15, position: e.value })} optionLabel='name' options={headlinePosition} />
                            </div>
                        </div>
                    </div>

                    {/* 
                    //*DESCRIPTION
                     */}

                    <div>
                        <div className='flex'>
                            <div className={`flex align-items-center justify-content w-3rem`}>
                                <h5 className='m-auto'>D1</h5>
                            </div>
                            <InputText className='ml-1 description' placeholder='Description 1' value={description1.description} onChange={(e) => setDescription1({ ...description1, description: e.target.value })} />
                            <div className={`flex ml-1 align-items-center justify-content border-400 border-round-sm border-1 border-solid-center w-3rem ${description1Severity ? 'bg-green-100' : 'bg-pink-200'}`}>
                                <h5 className='m-auto'>{`${description1.description.length}`}</h5>
                            </div>
                            <div className='ml-1'>
                                <SelectButton value={description1.position} onChange={(e) => setDescription1({ ...description1, position: e.value })} optionLabel='name' options={descriptionPosition} />
                            </div>
                        </div>
                        <div className='flex mt-1'>
                            <div className={`flex align-items-center justify-content w-3rem`}>
                                <h5 className='m-auto'>D2</h5>
                            </div>
                            <InputText className='ml-1 description' placeholder='Description 2' value={description2.description} onChange={(e) => setDescription2({ ...description2, description: e.target.value })} />
                            <div className={`flex ml-1 align-items-center justify-content border-400 border-round-sm border-1 border-solid-center w-3rem ${description2Severity ? 'bg-green-100' : 'bg-pink-200'}`}>
                                <h5 className='m-auto'>{`${description2.description.length}`}</h5>
                            </div>
                            <div className='ml-1'>
                                <SelectButton value={description2.position} onChange={(e) => setDescription1({ ...description2, position: e.value })} optionLabel='name' options={descriptionPosition} />
                            </div>
                        </div>
                        <div className='flex mt-1'>
                            <div className={`flex align-items-center justify-content w-3rem`}>
                                <h5 className='m-auto'>D3</h5>
                            </div>
                            <InputText className='ml-1 description' placeholder='Description 3' value={description3.description} onChange={(e) => setDescription3({ ...description3, description: e.target.value })} />
                            <div className={`flex ml-1 align-items-center justify-content border-400 border-round-sm border-1 border-solid-center w-3rem ${description3Severity ? 'bg-green-100' : 'bg-pink-200'}`}>
                                <h5 className='m-auto'>{`${description3.description.length}`}</h5>
                            </div>
                            <div className='ml-1'>
                                <SelectButton value={description3.position} onChange={(e) => setDescription1({ ...description3, position: e.value })} optionLabel='name' options={descriptionPosition} />
                            </div>
                        </div>
                        <div className='flex mt-1'>
                            <div className={`flex align-items-center justify-content w-3rem`}>
                                <h5 className='m-auto'>D4</h5>
                            </div>
                            <InputText className='ml-1 description' placeholder='Description 4' value={description4.description} onChange={(e) => setDescription4({ ...description4, description: e.target.value })} />
                            <div className={`flex ml-1 align-items-center justify-content border-400 border-round-sm border-1 border-solid-center w-3rem ${description4Severity ? 'bg-green-100' : 'bg-pink-200'}`}>
                                <h5 className='m-auto'>{`${description4.description.length}`}</h5>
                            </div>
                            <div className='ml-1'>
                                <SelectButton value={description4.position} onChange={(e) => setDescription1({ ...description4, position: e.value })} optionLabel='name' options={descriptionPosition} />
                            </div>
                        </div>

                        {/* 
                        //* PATH
                        */}

                        <div className='flex mt-1'>
                            <div className={`flex align-items-center justify-content w-3rem`}>
                                <h5 className='m-auto'>P1</h5>
                            </div>
                            <InputText className='ml-1 w-10rem' placeholder='Path 1' value={finalPath1.path} onChange={(e) => setFinalPath1({ ...finalPath1, path: e.target.value })} />
                            <div className={`flex ml-1 align-items-center justify-content border-400 border-round-sm border-1 border-solid-center w-3rem ${path1Severity ? 'bg-green-100' : 'bg-pink-200'}`}>
                                <h5 className='m-auto'>{`${finalPath1.path.length}`}</h5>
                            </div>
                        </div>
                        <div className='flex mt-1'>
                            <div className={`flex align-items-center justify-content w-3rem`}>
                                <h5 className='m-auto'>P2</h5>
                            </div>
                            <InputText className='ml-1 w-10rem' placeholder='Path 2' value={finalPath2.path} onChange={(e) => setFinalPath2({ ...finalPath2, path: e.target.value })} />
                            <div className={`flex ml-1 align-items-center justify-content border-400 border-round-sm border-1 border-solid-center w-3rem ${path2Severity ? 'bg-green-100' : 'bg-pink-200'}`}>
                                <h5 className='m-auto'>{`${finalPath2.path.length}`}</h5>
                            </div>
                        </div>

                        {/* 
                        //* FINAL AND MOBILE URL 
                        */}

                        <div className='flex mt-1'>
                            <div className={`flex align-items-center justify-content w-3rem`}>
                                <h5 className='m-auto'>FU</h5>
                            </div>
                            <InputText className='ml-1 description' placeholder='Final URL' value={finalURL.URL} onChange={(e) => setFinalURL({ ...finalURL, URL: e.target.value })} />
                            <div className={`flex ml-1 align-items-center justify-content border-400 border-round-sm border-1 border-solid-center w-3rem ${finalURLSeverity ? 'bg-green-100' : 'bg-pink-200'}`}>
                                <h5 className='m-auto'>{`${finalURL.URL.length}`}</h5>
                            </div>
                        </div>
                        <div className='flex mt-1'>
                            <div className={`flex align-items-center justify-content w-3rem`}>
                                <h5 className='m-auto'>MU</h5>
                            </div>
                            <InputText className='ml-1 description' placeholder='Mobile URL' value={finalMobileURL.URL} onChange={(e) => setFinalMobileURL({ ...finalMobileURL, URL: e.target.value })} />
                            <div className={`flex ml-1 align-items-center justify-content border-400 border-round-sm border-1 border-solid-center w-3rem ${finalMobileURLSeverity ? 'bg-green-100' : 'bg-pink-200'}`}>
                                <h5 className='m-auto'>{`${finalMobileURL.URL.length}`}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(GenerateADS);
