import { signal } from '@preact/signals-react';
import { filterKeywordsObject, filterKeywordsVisible, filterNegativeKeywords, filterNegativeWords, filterWordsObject } from '../FilterDataTable/FilterDataTableLogic';

export const clearKeywordsFilterConfirmDialogVisibility = signal(false);

export const clearKeywordsFilterFormIsLoading = signal({
    check: false,
    loading: false
});

export const resetKeywordsFilter = () => {
    filterKeywordsObject.value = '';
    filterWordsObject.value = '';
    filterNegativeKeywords.value = [];
    filterNegativeWords.value = [];
    setTimeout(() => {
        filterKeywordsVisible.value = false;
    }, 1000);
};

export const acceptClearKeywordsFilter = () => {
    clearKeywordsFilterFormIsLoading.value = {
        check: false,
        loading: true
    };
    setTimeout(() => {
        clearKeywordsFilterFormIsLoading.value = {
            check: true,
            loading: false
        };
    }, 2000);
    setTimeout(() => {
        resetKeywordsFilter();
        clearKeywordsFilterConfirmDialogVisibility.value = false;
        clearKeywordsFilterFormIsLoading.value = {
            check: false,
            loading: false
        };
    }, 2500);
};

// export const acceptDeleteKeywordsList = () => {
//     deleteKeywordsList();
//     setTimeout(() => {
//         getKeywordsList();
//     }, 2000);
// };

// export const rejectDeleteKeywordsList = () => {
//     deleteKeywordsListConfirmDialogVisibility.value = false;
// };
