import React from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { closeEditKeywordsListsForm, editInputWarn, editKeywordsFormIsLoading, editKeywordsListsFormComponentData, editKeywordsListsFormComponentVisibility, submitEditKeywordsListsForm } from './FormLogic';

export default function EditFormComponent() {
    return (
        <>
            <Dialog header='Edit Keywords List' footer='' visible={editKeywordsListsFormComponentVisibility.value} style={{ width: '15vw' }} onHide={closeEditKeywordsListsForm}>
                <InputText className={`mt-1 mb-2 w-17rem ${editInputWarn.input1}`} placeholder='Client' value={editKeywordsListsFormComponentData.client} onChange={(e) => (editKeywordsListsFormComponentData.client = e.target.value)} />
                <InputText className={`mb-2 w-17rem ${editInputWarn.input2}`} placeholder='Niche' value={editKeywordsListsFormComponentData.niche} onChange={(e) => (editKeywordsListsFormComponentData.niche = e.target.value)} />
                <InputText className={`mb-2 w-17rem ${editInputWarn.input3}`} placeholder='Language' value={editKeywordsListsFormComponentData.language} onChange={(e) => (editKeywordsListsFormComponentData.language = e.target.value)} />
                <div className='flex justify-content-end mr-2'>
                    <Button className='text-lg font-semibold' text raised label='Save' icon={`${editKeywordsFormIsLoading.value.check ? 'pi pi-check' : ''}`} loading={editKeywordsFormIsLoading.value.loading} onClick={submitEditKeywordsListsForm} />
                </div>
            </Dialog>
        </>
    );
}
