import React from 'react'
import { useToastContext } from '../../../../context/toastContext/toastContext';
import { useNavigate } from "react-router-dom";
import MicrosoftIcon from '../../../../assets/images/microsoft.png';
import { signInWithMicrosoft } from './MicrosoftSignInLogic';


export default function MicrosoftSignInComponent() {
    const { showToast } = useToastContext();
    const navigate = useNavigate()

    const handleClick = (event) => {
        signInWithMicrosoft(event, showToast, navigate);
    };



    return (
        <>
            {/* eslint-disable-next-line */}
            <a href="#" >
                <img onClick={handleClick} className="icon" src={MicrosoftIcon} alt="" />
            </a>
        </>
    )
}

