import { newKeywordsListsFormComponentData } from '../../service/KeywordsLists/addKeywordsListForm/FormLogic';
import { axiosBoilerPlate } from '../axiosBoilerPlate';

export const postKeywordsLists = async () => {
    const data = {
        method: 'POST',
        url: `${process.env.REACT_APP_AXIOS_BASEURL}keywordslists`,
        data: {
            user_id: localStorage.getItem('user_Id'),
            client: newKeywordsListsFormComponentData.client,
            niche: newKeywordsListsFormComponentData.niche,
            language: newKeywordsListsFormComponentData.language,
            non_filtered_keywords: newKeywordsListsFormComponentData.non_filtered_keywords,
            non_filtered_words: newKeywordsListsFormComponentData.non_filtered_words
        }
    };

    await axiosBoilerPlate(data);
};
