import { Dialog } from 'primereact/dialog';
import React from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { filterKeywordsObject, filterKeywordsVisible, filterNegativeKeywords, filterNegativeWords, filterWordsObject, keywordToNegativeFilter, negativeToKeywordFilter, negativeWordToWordFilter, wordToNegativeFilter } from './FilterDataTableLogic';
import { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode } from 'primereact/api';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { clearKeywordsFilterConfirmDialogVisibility } from '../clearKeywordsFilterTable/clearKeywordFilterDialogLogic';
import { saveKeywordsFilterConfirmDialogVisibility } from '../saveFilteredKeywordsDialog/saveFilteredKeywordsDialogLogic';

export default function FilterKeywordComponent() {
    const [globalKeywordFilterValue, setGlobalKeywordFilterValue] = useState('');

    const onGlobalKeywordFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...keywordFilters };
        _filters['global'].value = value;
        setKeywordsFilters(_filters);
        setGlobalKeywordFilterValue(value);
    };

    const onGlobalWordsFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...wordsFilters };
        _filters['global'].value = value;
        setWordsFilters(_filters);
        setGlobalWordsFilterValue(value);
    };

    const onGlobalNegativeKeywordsFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...negativeKeywordsFilters };
        _filters['global'].value = value;
        setNegativeKeywordsFilters(_filters);
        setGlobalNegativeKeywordsFilterValue(value);
    };

    const onGlobalNegativeWordsFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...negativeWordsFilters };
        _filters['global'].value = value;
        setNegativeWordsFilters(_filters);
        setGlobalNegativeWordsFilterValue(value);
    };

    const [keywordFilters, setKeywordsFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        negativeWords: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
    const [globalWordsFilterValue, setGlobalWordsFilterValue] = useState('');
    const [wordsFilters, setWordsFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        negativeWords: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
    const [globalNegativeKeywordsFilterValue, setGlobalNegativeKeywordsFilterValue] = useState('');
    const [negativeKeywordsFilters, setNegativeKeywordsFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        negativeWords: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
    const [globalNegativeWordsFilterValue, setGlobalNegativeWordsFilterValue] = useState('');
    const [negativeWordsFilters, setNegativeWordsFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        negativeWords: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });

    return (
        <Dialog header='Keywords Filter' visible={filterKeywordsVisible.value} maximizable onHide={() => (filterKeywordsVisible.value = false)}>
            <div>
                <div className='flex '>
                    <Button
                        text
                        raised
                        label='Save'
                        icon='pi pi-check-square'
                        className='ml-auto text-lg font-semibold'
                        onClick={() => {
                            saveKeywordsFilterConfirmDialogVisibility.value = true;
                        }}
                    />
                    <Button
                        text
                        raised
                        label='Clear and Close'
                        icon='pi pi-refresh  '
                        className='ml-2 text-lg font-semibold'
                        onClick={() => {
                            clearKeywordsFilterConfirmDialogVisibility.value = true;
                        }}
                    />
                </div>
                <div className='flex mt-3 .rise-up'>
                    <div className='w-3'>
                        <div className='flex'>
                            <span className='p-input-icon-left w-full mb-1'>
                                <i className='pi pi-search' />
                                <InputText className='w-full' type='search' value={globalKeywordFilterValue} onChange={onGlobalKeywordFilterChange} placeholder='In Keywords' />
                            </span>

                            <div className='ml-1 ' style={{ marginTop: '1px' }}>
                                <Message text={`${filterKeywordsObject.value.length}`} />
                            </div>
                        </div>

                        <DataTable
                            value={filterKeywordsObject.value}
                            filters={keywordFilters}
                            emptyMessage='No Keywords'
                            paginator
                            rows={12}
                            showGridlines
                            alwaysShowPaginator={false}
                            removableSort
                            cellSelection
                            selectionMode='single'
                            onSelectionChange={(e) => keywordToNegativeFilter(e.value.rowData)}
                            dataKey='id'
                        >
                            <Column key='Keyword' field='Keyword' header='Keyword' sortable />
                        </DataTable>
                    </div>
                    <div className='ml-1 w-3'>
                        <div className='flex'>
                            <span className='p-input-icon-left w-full mb-1'>
                                <i className='pi pi-search' />
                                <InputText className='w-full' type='search' value={globalWordsFilterValue} onChange={onGlobalWordsFilterChange} placeholder='In Words' />
                            </span>
                            <div className='ml-1' style={{ marginTop: '1px' }}>
                                <Message text={`${filterWordsObject.value.length}`} />
                            </div>
                        </div>
                        <DataTable // all words
                            value={filterWordsObject.value}
                            filters={wordsFilters}
                            emptyMessage='No Keywords'
                            paginator
                            rows={12}
                            showGridlines
                            alwaysShowPaginator={false}
                            removableSort
                            cellSelection
                            selectionMode='single'
                            onSelectionChange={(e) => wordToNegativeFilter(e.value.rowData)}
                            dataKey='id'
                        >
                            <Column key='Words' field='Words' header='Words' sortable />
                        </DataTable>
                    </div>
                    <div className='ml-1 w-3'>
                        <div className='flex'>
                            <span className='p-input-icon-left w-full mb-1'>
                                <i className='pi pi-search' />
                                <InputText className='w-full' type='search' value={globalNegativeKeywordsFilterValue} onChange={onGlobalNegativeKeywordsFilterChange} placeholder='In Negative Keywords' />
                            </span>
                            <div className='ml-1' style={{ marginTop: '1px' }}>
                                <Message text={`${filterNegativeKeywords.value.length}`} />
                            </div>
                        </div>
                        <DataTable // all negative keywords
                            value={filterNegativeKeywords.value}
                            filters={negativeKeywordsFilters}
                            emptyMessage='No Keywords'
                            paginator
                            rows={12}
                            showGridlines
                            alwaysShowPaginator={false}
                            removableSort
                            cellSelection
                            selectionMode='single'
                            onSelectionChange={(e) => negativeToKeywordFilter(e.value.rowData)}
                            dataKey='id'
                        >
                            <Column key='Keyword' field='Keyword' header='Negative Keyword' sortable />
                        </DataTable>
                    </div>
                    <div className='ml-1 w-3'>
                        <div className='flex'>
                            <span className='p-input-icon-left w-full mb-1'>
                                <i className='pi pi-search' />
                                <InputText className='w-full' type='search' value={globalNegativeWordsFilterValue} onChange={onGlobalNegativeWordsFilterChange} placeholder='In Negative Words' />
                            </span>
                            <div className='ml-1' style={{ marginTop: '1px' }}>
                                <Message text={`${filterNegativeWords.value.length}`} />
                            </div>
                        </div>
                        <DataTable // all negative words
                            value={filterNegativeWords.value}
                            filters={negativeWordsFilters}
                            emptyMessage='No Keywords'
                            paginator
                            rows={12}
                            showGridlines
                            alwaysShowPaginator={false}
                            removableSort
                            cellSelection
                            selectionMode='single'
                            onSelectionChange={(e) => negativeWordToWordFilter(e.value.rowData)}
                            dataKey='id'
                        >
                            <Column key='Words' field='Words' header='Negative Keyword' sortable />
                        </DataTable>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}
