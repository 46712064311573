import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { acceptClearKeywordsFilter, clearKeywordsFilterConfirmDialogVisibility, clearKeywordsFilterFormIsLoading } from './clearKeywordFilterDialogLogic';

export default function ClearKeywordsFilterConfirmDialog() {
    return (
        <>
            <Dialog header='CLEAR Filtered Results?' footer='' visible={clearKeywordsFilterConfirmDialogVisibility.value} style={{ width: '30rem' }} onHide={() => (clearKeywordsFilterConfirmDialogVisibility.value = false)}>
                <div className='flex'>
                    <Button className='text-lg font-semibold' text raised label='Cancel' icon='pi pi-times' onClick={() => (clearKeywordsFilterConfirmDialogVisibility.value = false)} />
                    <Button
                        className='text-lg font-semibold ml-auto'
                        severity='danger'
                        text
                        raised
                        label='Delete'
                        icon={`${clearKeywordsFilterFormIsLoading.value.check ? '' : 'pi pi-trash'}`}
                        loading={clearKeywordsFilterFormIsLoading.value.loading}
                        onClick={acceptClearKeywordsFilter}
                    />
                </div>
            </Dialog>
        </>
    );
}
