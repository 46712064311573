import { showToastHandler } from '../config/showToastHandler/showToastHandler';

export const handleErrorResponse = (error) => {
    let errorMessage = 'An unexpected error occurred';

    if (error.response) {
        switch (error.response.status) {
            case 400:
                errorMessage = 'Bad Request: Please check your input';
                break;
            case 401:
                errorMessage = 'Unauthorized: Please log in again';
                break;
            case 403:
                errorMessage = 'Forbidden: You do not have permission to access this resource';
                break;
            case 404:
                errorMessage = 'Not Found: The requested resource does not exist';
                break;
            case 500:
                errorMessage = 'Internal Server Error: Please try again later';
                break;
            default:
                errorMessage = `Error: ${error.response.statusText}`;
        }
    } else if (error.request) {
        errorMessage = 'Network Error: Unable to reach the server';
    }

    showToastHandler.value = {
        severity: 'error',
        message: errorMessage
    };

    console.error('Error details:', error);
};
