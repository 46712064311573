import { signal } from '@preact/signals-react';

import { getKeywordsList } from '../../../apiCalls/get/getKeywordsLists';
import { deleteKeywordsList } from '../../../apiCalls/delete/deleteKeywordsList';
import { deepSignal } from 'deepsignal/react';

export const deleteKeywordsListConfirmDialogVisibility = signal(false);

export const deleteKeywordsFormIsLoading = signal({
    check: false,
    loading: false
});

export const deleteKeywordsListConfirmDialogData = deepSignal({
    k_id: '',
    user_id: '',
    client: '',
    niche: '',
    language: ''
});

export const acceptDeleteKeywordsList = () => {
    deleteKeywordsFormIsLoading.value = {
        check: false,
        loading: true
    };
    deleteKeywordsList();
    setTimeout(() => {
        deleteKeywordsFormIsLoading.value = {
            check: true,
            loading: false
        };
    }, 2000);
    setTimeout(() => {
        deleteKeywordsListConfirmDialogVisibility.value = false;
        deleteKeywordsFormIsLoading.value = {
            check: false,
            loading: false
        };
        getKeywordsList();
    }, 2500);
};

// export const acceptDeleteKeywordsList = () => {
//     deleteKeywordsList();
//     setTimeout(() => {
//         getKeywordsList();
//     }, 2000);
// };

// export const rejectDeleteKeywordsList = () => {
//     deleteKeywordsListConfirmDialogVisibility.value = false;
// };
