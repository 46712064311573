import { signal } from '@preact/signals-react';

export const keywordsPreviewObject = signal('');

export const keywordsPreviewVisible = signal(false);

export const keywordsPreview = (rowData) => {
    keywordsPreviewObject.value = JSON.parse(rowData.non_filtered_keywords);
    keywordsPreviewVisible.value = true;
};
