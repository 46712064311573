import { Dialog } from 'primereact/dialog';
import React from 'react';
import { keywordsPreviewObject, keywordsPreviewVisible } from './KeywordPreviewLogic';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

export default function KeywordPreviewComponent() {
    return (
        <Dialog header='Keywords Preview' visible={keywordsPreviewVisible.value} maximizable onHide={() => (keywordsPreviewVisible.value = false)}>
            <DataTable value={keywordsPreviewObject.value} scrollable scrollHeight='flex' dataKey='id'>
                <Column field='Keyword'></Column>
            </DataTable>
        </Dialog>
    );
}
