// import { deepSignal } from 'deepsignal/react';
import { getAuth, getIdToken } from 'firebase/auth';
import { auth } from '../../../../config/firebase-config';
import axios from 'axios';

export const getNewToken = async () => {
    const currentUser = JSON.parse(localStorage.getItem('usercredentials'));
    console.log(currentUser);
    await getIdToken(currentUser, true)
        .then((token) => {
            if (token == null) {
                setTimeout(() => {
                    getNewToken();
                }, 10000);
            } else {
                console.log('Received New Token', token);
                localStorage.setItem('token', token);
            }
        })
        .then(() => {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
        })
        .catch((error) => {
            console.log('Error getting token:', error);
        });
};
