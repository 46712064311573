import { InputText } from 'primereact/inputtext'
import React from 'react'
import { emailAndPasswordSignIn, signInUserWithEmailAndPassword } from './EmailSignInLogic'
import { useToastContext } from '../../../../context/toastContext/toastContext';
import { useNavigate } from "react-router-dom";


export default function EmailSignInComponent() {
    const { showToast } = useToastContext();
    const navigate = useNavigate()

    const handleClick = (event) => {
        signInUserWithEmailAndPassword(event, showToast, navigate);
    };



    return (
        <>
            <h6 className="h6">or use your account credentials</h6>
            <InputText value={emailAndPasswordSignIn.email} className="w-18rem mb-2 mt-3" type="email" placeholder="Email" onChange={(e) => emailAndPasswordSignIn.email = e.target.value} />
            <InputText value={emailAndPasswordSignIn.password} className="w-18rem mb-2" type="password" placeholder="Password" onChange={(e) => emailAndPasswordSignIn.password = e.target.value} />
            <button onClick={handleClick} className="button" >Sign In</button>
            <a className="a" href="/#/app">
                <h6 className='mt-4'>Forgot your password?</h6>
            </a>
        </>
    )
}

