import axios from 'axios';
import { getNewToken } from '../pages/Login/SignIn/User/User';
import { showToastHandler } from '../config/showToastHandler/showToastHandler';
import { handleErrorResponse } from './handleErrorResponse';

export const axiosBoilerPlate = async (data) => {
    const answer = await axios(data)
        .then((response) => {
            if (response.data.success === '' || response.data.success === undefined) {
                showToastHandler.value = {
                    severity: 'error',
                    message: 'Invalid response from server'
                };
                // Handle the case where the response is not in the expected format
            } else if (response.data.success === 'false') {
                showToastHandler.value = {
                    severity: 'error',
                    message: response.data.message
                };
                // Handle the case where the response is not in the expected format
            } else {
                showToastHandler.value = {
                    severity: 'success',
                    message: response.data.success
                };
            }
            //* handle success
            return response;
        })

        //* Usage in axiosBoilerPlate or other API calls

        .catch((error) => {
            handleErrorResponse(error);
        })
        .finally(() => {
            // always executed
        });

    return answer;
};
