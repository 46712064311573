import { signOut } from 'firebase/auth';
import { auth } from '../../../config/firebase-config';

export const logOut = async (navigate) => {
    try {
        await signOut(auth).then(() => {
            localStorage.clear();
            navigate('/');
        });
    } catch (err) {
        console.log(err);
    }
};
