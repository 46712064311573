import { signal } from '@preact/signals-react';
import { resetKeywordsFilter } from '../clearKeywordsFilterTable/clearKeywordFilterDialogLogic';
import { filterKeywordsObject, filterNegativeKeywords, filterNegativeWords, filterWordsObject } from '../FilterDataTable/FilterDataTableLogic';

export const saveKeywordsFilterConfirmDialogVisibility = signal(false);

export const saveKeywordsFilterFormIsLoading = signal({
    check: false,
    loading: false
});

export const acceptSaveKeywordsFilter = () => {
    //* Deep clone of arrays of objects
    const fk = structuredClone(filterKeywordsObject.value);
    const fw = structuredClone(filterWordsObject.value);
    const nk = structuredClone(filterNegativeKeywords.value);
    const nw = structuredClone(filterNegativeWords.value);

    //* Reassign ids to arrays of objects

    fk.forEach((element, i) => {
        element.id = i + 1;
    });

    fw.forEach((element, i) => {
        element.id = i + 1;
    });

    nk.forEach((element, i) => {
        element.id = i + 1;
    });

    nw.forEach((element, i) => {
        element.id = i + 1;
    });

    saveKeywordsFilterFormIsLoading.value = {
        check: false,
        loading: true
    };
    setTimeout(() => {
        saveKeywordsFilterFormIsLoading.value = {
            check: true,
            loading: false
        };
    }, 2000);
    setTimeout(() => {
        // resetKeywordsFilter();
        saveKeywordsFilterConfirmDialogVisibility.value = false;
        saveKeywordsFilterFormIsLoading.value = {
            check: false,
            loading: false
        };
    }, 2500);
};
