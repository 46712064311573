import React from 'react';
import FilterKeywordsDataTable from './DataTable/DataTableComponent';
import FilterKeywordPreviewComponent from './keywordsPreview/FilterKeywordPreviewComponent';
import FilterKeywordComponent from './FilterDataTable/FilterDataTableComponent';
import ClearKeywordsFilterConfirmDialog from './clearKeywordsFilterTable/clearKeywordsFilterDialog';
import SaveFilteredKeywordsConfirmDialog from './saveFilteredKeywordsDialog/saveFilteredKeywordsDialog';

export default function KeywordsFilter() {
    return (
        <div className='card max-w-max m-auto .rise-up'>
            <SaveFilteredKeywordsConfirmDialog />
            <ClearKeywordsFilterConfirmDialog />
            <FilterKeywordComponent />
            <FilterKeywordPreviewComponent />
            <FilterKeywordsDataTable />
        </div>
    );
}
