import { editKeywordsListsFormComponentData } from '../../service/KeywordsLists/editKeywordsListForm/FormLogic';
import { axiosBoilerPlate } from '../axiosBoilerPlate';

export const putKeywordsList = async () => {
    const data = {
        method: 'PUT',
        url: `${process.env.REACT_APP_AXIOS_BASEURL}keywordslists`,
        data: {
            k_id: editKeywordsListsFormComponentData.k_id,
            user_id: localStorage.getItem('user_Id'),
            client: editKeywordsListsFormComponentData.client,
            niche: editKeywordsListsFormComponentData.niche,
            language: editKeywordsListsFormComponentData.language
        }
    };

    console.log(data);

    await axiosBoilerPlate(data);
};
