import { signal } from '@preact/signals-react';

export const filterKeywordsVisible = signal(false);
export const filterKeywordsKid = signal('');
export const filterKeywordsObject = signal([]);
export const filterWordsObject = signal([]);
export const filterNegativeKeywords = signal([]);
export const filterNegativeWords = signal([]);

//* Load of Initial Data To The Keywords Filtering Table

export const filterKeywordsStart = (rowData) => {
    console.log(rowData);
    filterKeywordsKid.value = rowData.k_id;
    filterKeywordsObject.value = JSON.parse(rowData.non_filtered_keywords);
    filterWordsObject.value = JSON.parse(rowData.non_filtered_words);
    filterKeywordsVisible.value = true;
};

//* Move Keyword To Negative Keyword

export const keywordToNegativeFilter = (rowData) => {
    filterNegativeKeywords.value = [...filterNegativeKeywords.value, rowData];
    let _products = filterKeywordsObject.value.filter(function (item) {
        return item !== rowData;
    });
    filterKeywordsObject.value = _products;
    filterNegativeKeywords.value = filterNegativeKeywords.value.sort((a, b) => {
        return a.id - b.id;
    });
};

//* Move Word To Negative Word

export const wordToNegativeFilter = (rowData) => {
    filterNegativeWords.value = [...filterNegativeWords.value, rowData];
    let _products = filterWordsObject.value.filter(function (item) {
        return item !== rowData;
    });
    filterWordsObject.value = _products;
    filterNegativeWords.value = filterNegativeWords.value.sort((a, b) => {
        return a.id - b.id;
    });
    filterKeywordsObject.value.forEach((element) => {
        if (element.Keyword.match(`\\b${rowData.Words}\\b`)) {
            filterNegativeKeywords.value = [...filterNegativeKeywords.value, element];
            filterKeywordsObject.value = filterKeywordsObject.value.filter((item) => {
                return item !== element;
            });
        }
    });
};

//* Move Negative Keyword To Keyword

export const negativeToKeywordFilter = (rowData) => {
    filterKeywordsObject.value = [...filterKeywordsObject.value, rowData];
    let _products = filterNegativeKeywords.value.filter(function (item) {
        return item !== rowData;
    });
    filterNegativeKeywords.value = _products;
    filterKeywordsObject.value = filterKeywordsObject.value.sort((a, b) => {
        return a.id - b.id;
    });
};

//* Move Negative Word To Word

export const negativeWordToWordFilter = (rowData) => {
    //* Here we return Negative Word to Word
    filterWordsObject.value = [...filterWordsObject.value, rowData];
    let _products = filterNegativeWords.value.filter(function (item) {
        return item !== rowData;
    });
    filterNegativeWords.value = _products;
    filterWordsObject.value = filterWordsObject.value.sort((a, b) => {
        return a.id - b.id;
    });
    //* Here we return Negative Keyword to Keyword
    filterNegativeKeywords.value.forEach((element) => {
        if (element.Keyword.match(`\\b${rowData.Words}\\b`)) {
            filterKeywordsObject.value = [...filterKeywordsObject.value, element];
            filterNegativeKeywords.value = filterNegativeKeywords.value.filter((item) => {
                return item !== element;
            });
        }
        filterKeywordsObject.value = filterKeywordsObject.value.sort((a, b) => {
            return a.id - b.id;
        });
    });
    filterKeywordsObject.value.forEach((element) => {
        filterNegativeWords.value.forEach((element2) => {
            if (element.Keyword.match(`\\b${element2.Words}\\b`)) {
                filterNegativeKeywords.value = [...filterNegativeKeywords.value, element];
                filterKeywordsObject.value = filterKeywordsObject.value.filter((item) => {
                    return item !== element;
                });
            }
        });
    });
};
